import React from 'react';

import { 
	Button,
	Container,
	Row,
	Col,
	Form
} from 'react-bootstrap'

function Page1(props) {
	return <div className="page-content">
			<div className="direct-connect-header jointpromotion">
				<div className="direct-connect-header-surround jointpromotion">
					<div className="direct-connect-header-title jointpromotion">JOINT PROMOTION</div>
					<div className="direct-connect-header-tagline jointpromotion">Joint Promotion is simply a cooperative marketing effort.</div>
					<div className="direct-connect-header-content jointpromotion">
						<p>
						Keeping the consumer in mind – the objective is to have participation by all of the recurring payment companies such as entertainment applications, gas, electric, water, telephone and cable companies for a territory so that a majority of households are able to select all their billers when enrolling. Occasionally we will have competitive billers listed on an enrollment form in overlapping territories, this can be viewed by some billers as a less than desirable scenario. In these instances, we just ask the objector to try to put themselves in the shoes of the consumer who wants to simplify their bill payments all in one shot. Their motives are for convenience which after all is what we all strive to offer them, this is not a venue where they feel prodded to get a competitive quote.
						</p>
					</div>
				</div>
			</div>
			<div className="directpromotion-graphic">
				<img width="100%" src={ process.env.PUBLIC_URL + '/img/arrows-3-circular-interlocking.png' }></img>
			</div>
			<div className="overview-wesupport">
				<div>A TWO-PRONGED APPROACH</div>
				<div>With this two-pronged enrollment option approach, you ensure a convenient means for your customers’ participation.</div>
			</div>
			<div className="twopronged">
			   <div className="twopronged-wrapper1">
					<div className="twopronged-col1">
						<div className="twopronged-title">PAPER</div>
						<div className="twopronged-tagline">The classic format for customer participation.</div>
						<div className="twopronged-content">
						The paper promotions occur in your market regularly whereby we list all the participating billers on our enrollment form (we can email or mail you a sample form as requested) and we go to print.  The quantity and cost of the forms are based on the aggregate demand of the billers. The billers on the list order forms from us and distribute them in bill inserts and as requested from their call centers.
						</div>
					</div>
				</div>
				<div className="twopronged-wrapper2">
					<div className="twopronged-col2">
						<div className="twopronged-title">WEBSITE</div>
						<div className="twopronged-tagline">The website enrollments program is ongoing, your customers can be notified of our website, various ways.</div>
						<div className="twopronged-content">
						Most commonly, we work with you to create a secure link on your site that brings your customer to our site to complete the enrollment process. Our customers also reference our website on their statements and in other correspondence to their customer base.
						</div>
					</div>
				</div>
			</div>
		</div>;
}

export default Page1;