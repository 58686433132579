import React from 'react';

function Page1(props) {
	return <div className="page-content">
			<div className="faq-page-title">FAQ'S</div>
			<div className="faq-section">
				<div className="faq-item">
					<div className="faq-title">
					CAN I USE YOUR SERVICE IN CONJUNCTION WITH MY COMPANY'S CURRENT BILLING SERVICE?
					</div>
					<div className="faq-content">
						<p>
						Yes! We work to get you more Direct Payment enrollments.
						</p>
						<p>
						We do not interfere with your current billing system, only enhance your ability to receive your collections in a more efficient and timely manner. Stop Processing Center (SPC) provides automated services to support a centralized operation for handling one-time data entry and internet capture of Direct Payment enrollments (authorizations) for multiple billers. The system stores the information in a secured encrypted database which is password accessible to participating billers who retrieve their enrollments from our biller retrieval website. This site provides downloading tools that enable you to review and edit the information prior to exporting a file or printing a report for updating your billing system.
						</p>
					</div>
				</div>
				<div className="faq-item">
					<div className="faq-title">
					WHAT IS THE COST OF YOUR SERVICE?
					</div>
					<div className="faq-content">
						<p>				
						There is no cost to your customer. Enrollment pricing is based on two components of each data entry form and internet enrollment processed. The "Header" (the customer, name and address, banking information) a cost-shared by each biller included on an enrollment) and the "Line Item" (the individual biller account information). You, the biller, split the cost of the header for each biller included on an enrollment.
						</p>
						<p className="faq-content-note">
						Note: Most programs experience two or more biller enrollments per-form or internet form processed.
						</p>
						<p>
						There is also a minimal fee for access to our Direct Payment and SPC biller retrieval websites for security updates, responding to e-mail service questions, and providing site access for billers. The monthly charge is based on the number of biller customers.
						</p>
					</div>
				</div>
				<div className="faq-item">
					<div className="faq-title">
					HOW DO WE RECEIVE OUR ENROLLMENTS?
					</div>
					<div className="faq-content">
						<p>
						We offer two ways to securely receive your enrollments. One way is by file downloads that you, the biller, securely download from our site and directly bring into your billing system. We also provide an automated FTP file delivery integration (available in multiple formats), a direct interface with your system for seamless payment posting. Your data is encrypted and password protected using both means.
						</p>
					</div>
				</div>
				<div className="faq-item">
					<div className="faq-title">
					WHAT OTHER SERVICES DO YOU OFFER?
					</div>
					<div className="faq-content">
						<p>
						SPC offers you:
						</p>
						<ul>
							<li>
							The <a target="_blank" href="https://www.directpaymentplan.com/">Direct Payment Plan</a> website for promotion of direct payment enrollment.
							</li>
							<li>
							Email confirmations to your customer upon completion of their website enrollment.
							</li>
							<li>
							Routing and transit number editing and crossover editing.
							</li>
							<li>
							On-going contact regarding incorrect or missing enrollment information.
							</li>
							<li>
							Biller number format editing and notation.
							</li>
							<li>
							Secure email communications between billers and SPC.
							</li>
							<li>
							Access to the <a target="_blank" href="https://biller.stopprocessing.com/">SPC biller retrieval</a> website​ for securely downloading enrollments.
							</li>
							<li>
							Password protection and data encryption.
							</li>
							<li>
							Formatted display of enrollments for review and correction.
							</li>
							<li>
							Simplified reports used for biller system data entry (new enrollments).
							</li>
							<li>
							Enrollment record retention (paper and electronic).
							</li>
							<li>
							Enrollment error resolution.
							</li>
							<li>
							Exporting capabilities to biller systems (available in multiple formats).
							</li>
							<li>
							Catch and redirect enrollments that customers mislabeled to the wrong biller.
							</li>
							<li>
							Support for setup and initialization of enrollment retrieval process.
							</li>
							<li>
							Paper form opening, preparing, and data entry of paper enrollments.
							</li>
							<li>
							Forward customer bill payments to billers that were received with enrollments.
							</li>
							<li>
							Retrieval and email or fax delivery of enrollments requested by billers for special inquiries (excessive requests will incur an additional $1.00 handling charge).
							</li>
							<li>
							Twelve-month enrollment results by biller on the <a target="_blank" href="https://biller.stopprocessing.com/">SPC biller retrieval</a> website.
							</li>
							<li>
							Resolve problems and respond to questions emailed from customers related to their enrollment on <a target="_blank" href="https://www.directpaymentplan.com/">Direct Payment Plan</a> website.
							</li>
							<li>
							SPC does not interact with customers concerning incorrect enrollments. All incomplete information is noted to billers for their resolution.
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="faq-section-title">YOUR CUSTOMERS' FAQ'S</div>
			<div className="faq-section">
				<div className="faq-item">
					<div className="faq-title">
					HOW MUCH DOES DIRECT PAYMENT COST?
					</div>
					<div className="faq-content">
						<p>
						There is no charge for enrolling through the <a target="_blank" href="https://www.directpaymentplan.com/">Direct Payment Plan</a> website. Also, your biller does not charge for the Direct Payment service. Your financial institution account may incur a service charge for each Direct Payment similar to the charge for checks that clear against your account. Some financial institutions do not charge service fees depending on the balance in the account or for other reasons. Contact your financial institution for service charge information.
						</p>
					</div>
				</div>
				<div className="faq-item">
					<div className="faq-title">
					WHEN WILL THE PAYMENTS BE CHARGED AGAINST MY ACCOUNT?
					</div>
					<div className="faq-content">
						<p>
						Your billers will notify you each month on your billing statement when the charge will be applied to your bank account and the amount. You will receive the statement 8 to 10 days in advance of the due date. Most billers will use your current due date for your Direct Payment.
						</p>
					</div>
				</div>
				<div className="faq-item">
					<div className="faq-title">
					HOW LONG DOES IT TAKE TO START WORKING? WHY?
					</div>
					<div className="faq-content">
						<p>
						We tell the customers it takes up to 4 weeks for the Direct Payment service to go into effect. Your statement from your biller will indicate when Direct Payment is working and you can stop paying by check.
						</p>
						<p>
						Even though you are enrolling in electronic payments it takes time to set up the Direct Payment option for the following reasons:
						<ol>
							<li>
							Not all billers are able to accept customers' enrollment directly into their billing system, thus the information is entered manually.
							</li>
							<li>
							Some billers use a pre-note process that sends a test payment (zero amount) to check to see if their customers' banking information is okay. It depends on where the customers' account is in their billing cycle as to when the test is run. Then, billers have to wait to see if the test worked.
							</li>
							<li>
							Billers process a customers' bill on cycles, thus billers will not start a new enrollment until the next billing cycle.
							</li>
							<li>
							The electronic payment rules require the biller to send their customers' statement ten days in advance of the electronic payment.
							</li>
						</ol>
						</p>
					</div>
				</div>
				<div className="faq-item">
					<div className="faq-title">
					HOW DO I MAKE CHANGES OR ADDITIONS TO AN ENROLLMENT?
					</div>
					<div className="faq-content">
						<p>
						Once your request has been confirmed you cannot access it to make changes or additions. All of the information is forwarded to the billers requested. For security reasons, we cannot keep this information online. To add additional billers or make banking changes you must complete another enrollment. Just enter a new enrollment on the <a target="_blank" href="https://www.directpaymentplan.com/">Direct Payment Plan</a> website. The new information will replace the banking information currently on your billing record. Remember that it could take up to 4 weeks for it to take effect so do not close your old account until the payments are working against the new one.
						</p>
					</div>
				</div>
				<div className="faq-item">
					<div className="faq-title">
					HOW DO I CHANGE THE ACCOUNT THE PAYMENT IS COMING FROM?
					</div>
					<div className="faq-content">
						<p>
						Simply enter a new enrollment. The new information will replace the banking information currently on your billing record. Remember that it could take up to 4 weeks for it to take effect, so do not close your old account until the payments are working against the new one.
						</p>
					</div>
				</div>
				<div className="faq-item">
					<div className="faq-title">
					HOW DO I CANCEL MY SERVICE?
					</div>
					<div className="faq-content">
						<p>
						To cancel the service you must contact each biller directly. Any attempt to cancel the service by e-mails through this site will not be acted upon and will delay solving your situation. Reference each company's invoice for a customer service phone number or your phone directory.
						</p>
						<p>
						The <a target="_blank" href="https://www.directpaymentplan.com/">Direct Payment Plan</a> website is for the purpose of enrollment in Direct Payment. Your information is immediately forwarded to your billers and not retained by the site.
						</p>
					</div>
				</div>
			</div>
		</div>;
}

export default Page1;