import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

import { 
	Navbar, 
	Nav, 
	NavDropdown, 
	Container, 
	Col, 
	Row } from 'react-bootstrap'

import {
	Routes,
	Route,
} from "react-router-dom";

import {
	HashLink as Link
} from "react-router-hash-link";

import HomePage from './views/HomePage';
import AboutPage from './views/AboutPage';
import FAQsPage from './views/FAQsPage';
import JointPromotion from './views/JointPromotionPage';
import OverviewPage from './views/OverviewPage';

function App() {

	return (
		<div className="App">
			<div className="app-header">
				<Navbar expand="lg">
					<Container>
						<Navbar.Brand as={ Link } to="/">
							<Container>
								<Row>
									<Col className="header-logo">
										<img
											alt="Stop Processing"
											src={process.env.PUBLIC_URL + '/logo-stop.svg'}
											width="92"
											/>
									</Col>
									<Col className="header-company-name">
										<span className="header-company-name-span">STOP PROCESSING, INC.</span><br/>
										<span className="header-company-tagline-span">Working Together for More Direct Payment Participation</span>
									</Col>
								</Row>
							</Container>
						</Navbar.Brand>
						<Navbar.Toggle />
						<Navbar.Collapse className="justify-content-end">
							<Nav className="ml-auto">
								<Nav.Link as={ Link } to="/">Home</Nav.Link>
								<NavDropdown title="About" id="aboutId">
									<NavDropdown.Item as={ Link } to="/about">Our Expertise</NavDropdown.Item>
									<NavDropdown.Item as={ Link } to="/about#history">Our History</NavDropdown.Item>
									<NavDropdown.Item as={ Link } to="/about#founder">Our Founder</NavDropdown.Item>
								</NavDropdown>
								<NavDropdown title="Company" id="overviewId">
									<NavDropdown.Item as={ Link } to="/overview">Overview</NavDropdown.Item>
									<NavDropdown.Item as={ Link } to="/overview#whoarewe">Who Are We?</NavDropdown.Item>
									<NavDropdown.Item as={ Link } to="/overview#whoareyou">Who Are You?</NavDropdown.Item>
									<NavDropdown.Item as={ Link } to="/overview#flowchart">Flow Chart</NavDropdown.Item>
									<NavDropdown.Item as={ Link } to="/overview#technology">Technology</NavDropdown.Item>
								</NavDropdown>
								<Nav.Link as={ Link } to="/jointpromotion">Joint&nbsp;Promotion</Nav.Link>
								<Nav.Link as={ Link } to="/faqs">FAQs</Nav.Link>
								<Nav.Link href="https://biller.stopprocessing.com">Biller&nbsp;Sign&nbsp;In</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>        
			</div>
			<div className="app-body">
				<Routes>
					<Route index path="/" element={ <HomePage /> } />
					<Route path="/index.html" element={ <HomePage /> } />
					<Route path="/about" element={ <AboutPage /> } />
					<Route path="/faqs" element={ <FAQsPage /> } />
					<Route path="/jointpromotion" element={ <JointPromotion /> } />
					<Route path="/overview" element={ <OverviewPage /> } />
				</Routes>
			</div>
			<div className="app-footer">
				<div className="app-footer-company">Stop Processing, Inc.</div>
				<div className="app-footer-mailto href-no-hover"><a target="_blank" href="mailto:info@stopprocessing.com">info@stopprocessing.com</a></div>
				<div className="app-footer-phone">Phone: (440) 564-1087&nbsp;&nbsp;&nbsp;Fax: (440) 564-1169</div>
				<div className="app-footer-social href-no-hover"><a target="_blank" href="https://www.linkedin.com/company/stop-processing-inc/about/"><FontAwesomeIcon icon={faLinkedin} /></a></div>
				<div className="app-footer-copyright">Copyright ©2002-2021 Stop Processing Center. All rights reserved.</div>
			</div>
		</div>
	);
}

export default App;
