import React, { useState } from 'react';

import {
	HashLink as Link
} from "react-router-hash-link";

import { 
	Button,
	Container,
	Row,
	Col,
	Form,
	Spinner,
	Alert
} from 'react-bootstrap'

export async function submitFormData(sendObj)
{
	let responseObj =  {
		status: 500,
		statusText: undefined,
		errorText: undefined,
		response: undefined
	};

	const lambdaURL = 'https://lambda.directpaymentplan.com/dppapi-prod/';

	try 
	{
		var url = lambdaURL + sendObj.command;
		//console.log("url: "+url);

		var http = new XMLHttpRequest();

		http.open('POST', url, false);

		http.setRequestHeader('Content-type', 'application/json;charset=UTF-8');

		http.onreadystatechange = function() 
		{
			if (http.readyState == 4) 
			{
				responseObj.status = http.status;
				responseObj.statusText = http.responseText;

				if (http.status >= 200 && http.status < 300)
				{
					try
					{
						responseObj.response = JSON.parse(http.responseText);
					}
					catch(e2)
					{
						responseObj.errorText = e2.message;
					}
				}
			}
		}

		await http.send(JSON.stringify(sendObj));
	}
	catch (e) 
	{
		responseObj.errorText = e.message;
	}  

	return responseObj;
}

const defaultFormValues = {
	firstName: '', 
	lastName: '', 
	companyName: '', 
	address: '<Message is from new stopprocessing.com website>', 
	address2: '', 
	city: '', 
	state: '', 
	zip: '', 
	phone: '', 
	emailAddress: '',
	comment: '', 
};

function HomePage(props) {

    const [values, setValues] = useState(defaultFormValues)

	const handleInputChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
	}
	
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState('');
	const [formSubmitSuccess, setFormSubmitSuccess] = useState('');
	const [showSpinner, setShowSpinner] = useState(false);

	const doSubmit = async function(values)
	{
		let sendObj = {
			command: "form-request-info",
			version: 1,
			formData: values
		}
	
		let responseObj = await submitFormData(sendObj);
	
		//console.log(JSON.stringify(responseObj, null, 2));
	
		if (responseObj && responseObj.response && responseObj.response.statusCode == 200)
		{
			setFormSubmitted(true);
			setFormSubmitSuccess('Thank you for contacting us! We will get back to you ASAP.');
			setValues(defaultFormValues);
		}
		else
		{
			setFormSubmitError('There was a problem submitting your information. Try again later.');
		}
		
		setShowSpinner(false);
	}

	let enableSubmit = (
		values.firstName.length > 0 && 
		values.lastName.length > 0 && 
		(values.phone.length > 0 ||
		values.emailAddress.length > 0) &&
		values.comment.length > 0
		);

	return <div className="page-content">
			<div className="contentarea-single-center" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + '/img/HomepageOffice.jpg' + ")" }}>
				<div className="contentarea-single-center-surround">
					<div className="contentarea-single-center-title">WELCOME TO STOP PROCESSING, INC.</div>
					<div className="contentarea-single-center-tagline">We are third party teamwork without the third party price tag.</div>
					<div className="contentarea-single-center-content">Stop Processing Center (SPC) works with companies like yours under one sole purpose; to maximize your electronic payment participation levels in your customer base. Direct Payment collections help to ensure high profitability via more expedient collections. We can work with you to capture more Direct Payment enrollments.</div>
					<div className="contentarea-single-center-button"><Button as={ Link } to="#contactus">Contact Us For More Info</Button></div>
				</div>
			</div>
			<div className="sidebyside">
				<div className="sidebyside-col1">
					<div className="sidebyside-col1-surround">
						<div className="sidebyside-col1-title">WE'VE BEEN HERE SINCE THE BEGINNING</div>
						<div className="sidebyside-col1-tagline">Over 25 years ago it became clear that electronic payments were going to be the future of bill collections for all.</div>
						<div className="sidebyside-col1-content">The Federal Reserve and regional financial institutions across the nation saw that electronic payments was an aspirational goal to ensure more expedient bill collections. Promotion of this initiative nationally became the order of the day. And so became the seeds of the inception of the Stop Processing Center.</div>
						<div className="sidebyside-col1-button"><Button as={ Link } to="/overview#overviewtop">Learn More About Us</Button></div>
					</div>
				</div>
				<div className="sidebyside-col2">
					<div className="sidebyside-empty-img" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + '/img/StandUpMeetingCrop.jpg' + ")" }}>
						<span>&nbsp;</span>
						<img src={ process.env.PUBLIC_URL + '/img/StandUpMeetingCrop.jpg' }></img>
					</div>
				</div>
			</div>
			<div id="contactus" className="sidebyside">
				<div className="sidebyside-col2">
					<div className="sidebyside-empty-img" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + '/img/HotelCorridor.jpg' + ")" }}>
						<span>&nbsp;</span>
					</div>
				</div>
				<div className="sidebyside-col3">
					<div className="sidebyside-col3-surround">
						<div className="sidebyside-col3-title">Contact Us</div>
						<div className="sidebyside-col3-form">
							{ formSubmitError.length > 0 &&
								<Alert dismissible onClose={() => setFormSubmitError('')} variant="danger">{ formSubmitError }</Alert>
							}
							{ formSubmitSuccess.length > 0 &&
								<Alert dismissible onClose={() => setFormSubmitSuccess('')} variant="success">{ formSubmitSuccess }</Alert>
							}
							<Form>

								<Form.Group className="mb-3" controlId="firstName">
									<Form.Label>First Name</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Enter First Name" 
										name="firstName"
										onChange={ handleInputChange }
										value={ values.firstName }
										/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="lastName">
									<Form.Label>Last Name</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Enter Last Name" 
										name="lastName"
										onChange={ handleInputChange }
										value={ values.lastName }
										/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="companyName">
									<Form.Label>Company</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Enter Company Name" 
										name="companyName"
										onChange={ handleInputChange }
										value={ values.companyName }
										/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="phone">
									<Form.Label>Phone</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Enter Phone Number" 
										name="phone"
										onChange={ handleInputChange }
										value={ values.phone }
										/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="emailAddress">
									<Form.Label>Email Address</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Enter Email Address" 
										name="emailAddress"
										onChange={ handleInputChange }
										value={ values.emailAddress }
										/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="comment">
									<Form.Label>Message</Form.Label>
									<Form.Control 
										placeholder="Enter Message" 
										as="textarea" rows={6} 
										name="comment"
										onChange={ handleInputChange }
										value={ values.comment }
										/>
								</Form.Group>

								<div className="sidebyside-col3-spacer"></div>

								<Button disabled={ !enableSubmit } onClick={ async () => {

										setShowSpinner(true);

										setTimeout(function () { doSubmit(values) }, 500);

									} } variant="primary">
									{ showSpinner &&
											<span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span> 
									}
									<span>Submit</span></Button>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</div>;
}

export default HomePage;