import React from 'react';

import {
	HashLink as Link
} from "react-router-hash-link";

import { 
	Button,
	Container,
	Row,
	Col,
	Form
} from 'react-bootstrap'

function Page1(props) {
	return <div className="page-content">
			<div className="sidebyside">
				<div className="sidebyside-col1">
					<div className="sidebyside-col1-surround page-about">
						<div className="sidebyside-col1-title">OUR EXPERTISE</div>
						<div className="sidebyside-col1-content">Our founder represents many years of experience in working with financial corporations. Expertise is invaluable in developing strategies to introduce joint promotion programs in new territories of the country. In addition, expertise in the use of automation assures that business growth will be supported by technology that continues to deliver services required by the market.</div>
						<div className="sidebyside-col1-button"><Button as={ Link } to="/#contactus">Get In Touch</Button></div>
					</div>
				</div>
				<div className="sidebyside-col2">
					<div className="sidebyside-empty-img" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + '/img/YoungGoodFamily.jpg' + ")" }}>
						<span>&nbsp;</span>
					</div>
				</div>
			</div>
			<div id="history" className="contentarea-single-center" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + '/img/1980sBank.jpg' + ")" }}>
				<div className="contentarea-single-center-surround">
					<div className="contentarea-single-center-title">STOP HISTORY</div>
					<div className="contentarea-single-center-tagline">The Inception of Stop Processing</div>
					<div className="contentarea-single-center-content">
						<p>
						The Direct Payment enrollment program was born out of necessity starting in 1997. For many years prior the Federal Reserve Bank of Cleveland, OH in conjunction with a non-profit organization called Mid-America Payment Systems, promoted paperless payment solutions through banks and utility companies. The reaction to seminars conducted was always very positive however a common result still today is that many of the billers who implement Direct Payments do not have the human resources necessary to develop the program to its’ full potential.
						</p>
						<p>
						At first, we strictly captured enrollments using paper forms distributed by utility companies and financial institutions. We data entered the forms on a secured site and created a daily file for retrieval. Over the years Stop Processing Center Inc. has developed an online presence capturing enrollment data on our website and uses FTP delivery of enrollments to the billers in a safe, secure manner. We still work hard gathering enrollment information using both paper enrollments and electronically.
						</p>
						<p>
						Stop Processing, to this day, continues to enjoy partnering with companies (billers) and it is quite evident that the average household demands a Direct Payment plan option in bill payments. With less and less time to manage a household, your customers are demanding a more efficient means to pay bills from their bank accounts. They expect to be able to have a Direct Payment option available from all of their billers. The Stop Processing model is to bring all their billers to them on one platform called Joint Promotion.
						</p>
					</div>
				</div>
			</div>
			<div id="founder" className="ourfounder">
				<div className="ourfounder-col1">
					<img width="100%" height="100%" src={ process.env.PUBLIC_URL + '/img/TheMostInterestingMan.jpg' }></img>
				</div>
				<div className="ourfounder-col2">
					<div className="ourfounder-col2-surround">
						<div className="ourfounder-col2-title">OUR FOUNDER</div>
						<div className="ourfounder-col2-tagline">John F. Good</div>
						<div className="ourfounder-col2-content">
							<p>
							John attended Ohio University and is a lifelong resident of northeast, Ohio. During his final years of formal employment, John served as the Executive Director of the MidAmerica Automated Payments System (MAPS) out of Cleveland, Ohio.  He also spent time as the Director of the Automated Clearing House Association, at the time serving the northern 39 counties of Ohio.  The organization was supported by a membership of more than 235 Banks, Savings Institutions, and Credit Unions and provided sales, marketing and operations support to the Automated Clearing House (ACH) electronic payments network. John’s background prior to joining MAPS includes ten years of employment with Huntington Banks (formerly Union Commerce Bank), of which five years served as Officer responsible for Cash Management Operations.  Prior to this, served in data processing capacities as manager of programming and manager of systems support.
							</p>
							<p>
							Additional professional background includes five years of employment with Computer Management Incorporated as a project leader responsible for software development in a variety of industries, businesses, including major banking projects. Other Data Processing experience includes nine years combined employment with White Motors Corporation and Bailey Controls starting with first-generation computers.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>;
}

export default Page1;