import React from 'react';

import {
	HashLink as Link
} from "react-router-hash-link";

import { 
	Button,
	Container,
	Row,
	Col,
	Form
} from 'react-bootstrap'

function Page1(props) {
	return <div id="overviewtop" className="page-content">
			<div className="contentarea-single-center overview-rationale" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + '/img/Staircase.jpg' + ")" }}>
				<div className="contentarea-single-center-surround overview-rationale">
					<div className="contentarea-single-center-title overview-rationale">RATIONALE FOR STOP PROCESSING</div>
					<div className="contentarea-single-center-content overview-rationale">
						<p>
						In many specific cases, companies simply cannot dedicate the resources to capture the data entry&#8230;
						</p>
						<p>
						let alone following up and retrieving incomplete enrollment data. This component includes all the nitty-gritty aspects of what we call “cleaning up the data”:
						</p>
						<ul>
							<li>Financial Institution routing and transit numbers verification, editing, and cross-over</li>
							<li>Biller validation, format editing, and notation</li>
							<li>Biller account number screening</li>
							<li>Incomplete enrollment follow up</li>
						</ul>
						<p>
						For many billers, this component of enrolling customers in Direct Payments is out-sourced to Stop Processing.
						</p>
						<p>
						We have years of experience, the endorsement of the Federal Reserve, and the access to ensure your enrollments are “clean”.
						</p>
					</div>
				</div>
			</div>
			<div className="overview-wesupport">
				<div>WE SUPPORT YOUR EFFORTS</div>
				<div>Currently many companies offer Direct Payments to their customer base and understand the win-win efficiencies.</div>
			</div>
			<div className="featurebenefit">
				<div className="featurebenefit-wrapper1">
					<div className="featurebenefit-col1">
						<div className="featurebenefit-title">COMPANY</div>
						<div className="featurebenefit-wrapper">
							<div>Good for the Environment</div>
							<div>Fewer paper checks to process</div>
						</div>
						<div className="featurebenefit-wrapper">
							<div>Great for the Bottom Line</div>
							<div>On-time receivables for the company</div>
						</div>
						<div className="featurebenefit-wrapper">
							<div>Saves Time</div>
							<div>No more chasing late payments</div>
						</div>
					</div>
				</div>
				<div className="featurebenefit-wrapper2">
					<div className="featurebenefit-col2">
						<div className="featurebenefit-title">CUSTOMER</div>
						<div className="featurebenefit-wrapper">
							<div>Save a Stamp</div>
							<div>Less cost for the customer </div>
						</div>
						<div className="featurebenefit-wrapper">
							<div>No Late Fees</div>
							<div>On time payments for the customer</div>
						</div>
						<div className="featurebenefit-wrapper">
							<div>Helps Free Up Your Mind and Time</div>
							<div>Scheduled payments</div>
						</div>
					</div>
				</div>
			</div>
			<div id="whoarewe" className="contentarea-single-center overview-rationale" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + '/img/EmptyOffice.jpg' + ")" }}>
				<div className="contentarea-single-center-surround overview-rationale">
					<div className="contentarea-single-center-title overview-rationale">WHO ARE WE?</div>
					<div className="contentarea-single-center-content overview-rationale">
						<p>
						The Stop Processing Center (SPC) provides processing services for enrollments for direct payments in conjunction with a joint promotion program. It is a comprehensive system for handling the capture and automated delivery of enrollment information. We process both online and paper enrollment data. Direct Payment is the capability for billers (any company collecting recurring payments) to collect a customer's bill by debiting their bank account instead of receiving payments by check. 
						</p>
						SPC retains the electronic enrollment information for billers in accordance with ACH rules. 
						<p>
						</p>
						<p>
						SPC assists the biller’s customer service in problem resolution regarding customer enrollments. 
						</p>
						<p>
						The joint promotion program has been operating for more than twenty years. It provides a convenient method for consumers enrolling in direct payments to enroll one time with multiple billers.  Joint promotion is also a benefit to the billers with regards to shared marketing and a price discount for enrollments received with multiple billers. This service is available for the customer when they enroll with their utility company for the first time or when they change their account to another financial institution.
						</p>
					</div>
				</div>
			</div>
			<div id="whoareyou" className="contentarea-single-center overview-rationale" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + '/img/CafeMeeting.jpg' + ")" }}>
				<div className="contentarea-single-center-surround overview-rationale">
					<div className="contentarea-single-center-title overview-rationale">WHO IS A STOP PROCESSING CUSTOMER?</div>
					<div className="contentarea-single-center-content overview-rationale">
						<p>
						We are designed to work alongside any company collecting recurring payments.
						</p>
						<p>
						SPC currently provides direct payment enrollment services to companies (billers) representing major and municipal electric, gas and water companies. We maintain a day-to-day relationship with you in order to deliver sign-ups, resolve signup issues, and receive payment for services. Our relationship as a service provider includes any necessary contact with marketing, billing operations, and accounts payable functions. 
						</p>
						<p>
						Our website <a href="https://www.directpaymentplan.com">Direct Payment Plan</a> is your consumer’s enrollment website. It represents the direct payment service and is a resource that is promoted by you and linked to your website. An SPC partnership also includes hundreds of financial institutions that promote the program. They can also link to the <a href="https://www.directpaymentplan.com">Direct Payment Plan</a> website and encourage their customers to use our website to update their payment information.
						</p>
					</div>
				</div>
			</div>

			<div id="flowchart" className="flowchart">
				<div className="flowchart-col1">
					<div className="flowchart-title">FLOW CHART</div>
					<div className="flowchart-content">Like any efficient company, SPC has its own flow. Take a look at our chart to see how we optimize our process.</div>
				</div>
				<div className="flowchart-col2">
					<img width="100%" src={ process.env.PUBLIC_URL + '/img/FlowChart.jpg' }></img>
				</div>
			</div>

			<div id="technology" className="technology">
				<div className="technology-title">TECHNOLOGY AT STOP PROCESSING, INC.</div>
				<div className="technology-grid-container">
					<div className="technology-grid">
						<div className="technology-grid-col-text">
							<div className="technology-grid-text-wrapper">
								<div className="technology-grid-col-title">TECHNOLOGY</div>
								<div className="technology-grid-col-content">The SPC services consist of technology that cost-effectively supports the capture and delivery of automated bill payment enrollments. The technology is designed for scalable growth and uses the Internet for fast and efficient delivery of services. The ability to add new participants, provide quality information, and grow with the market is a key attribute of the technology.</div>
							</div>
						</div>
						<div className="technology-grid-col-image">
							<img width="100%" src={ process.env.PUBLIC_URL + '/img/MultiDevices.jpg' }></img>
						</div>
					</div>
					<div className="technology-grid">
						<div className="technology-grid-col-image">
							<img width="100%" src={ process.env.PUBLIC_URL + '/img/WorkingOnLaptopWithCoffee.jpg' }></img>
						</div>
						<div className="technology-grid-col-text">
							<div className="technology-grid-text-wrapper">
								<div className="technology-grid-col-title">BILLER SIGN-UP RETRIEVAL</div>
								<div className="technology-grid-col-content">Billers access our stopprocessing.com website using the web retrieve routines. After entering a username and password, a series of screens provides control over viewing, editing and retrieving their sign-ups. Billers can download current and previous sign-ups and also create files for direct updating of their billing systems. Billers can also access year-to-date sign-up statistics.</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>;
}

export default Page1;